import React from 'react'
import classNames from 'classnames'

import { Body, SpecialHeader2 } from './shared/Text'
import Markdown from './shared/Markdown'

const SectionHeader = ({ className = '', title, paragraph, children }) => (
  <header
    className={classNames(
      'flex flex-col items-baseline gap-3 py-6 lg:mb-8 lg:items-center lg:gap-8 lg:text-center',
      className,
      { 'lg:!mb-0 lg:pb-0': !children }
    )}
  >
    {title && <SpecialHeader2>{title}</SpecialHeader2>}
    {paragraph && (
      <Body className="lg:max-w-[50vw] 2xl:text-[2.5rem]">
        <Markdown className="2xl:leading-[3rem]">{paragraph}</Markdown>
      </Body>
    )}
    {children}
  </header>
)

export const HeroHeader = props => (
  <SectionHeader
    className="lg:items-baseline lg:text-start"
    {...props}
    // eslint-disable-next-line react/destructuring-assignment
    title={<span className="text-left">{props.title}</span>}
  />
)

export const SupportHeader = props => (
  <SectionHeader
    className="max-xs:py-0 lg:items-baseline lg:text-start"
    {...props}
  />
)

export default SectionHeader
