import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css' // <-- media queries here
import useMedia from '../../../hooks/useMedia'

const Carousel = ({ children, cardsPerPage, ...rest }) => {
  const isMediumRes = useMedia('(min-width: 1024px)')
  const perPage = cardsPerPage ?? (isMediumRes ? 3 : 1)

  return (
    <Slider
      dots
      infinite
      autoplay
      autoplaySpeed={4000}
      speed={300}
      arrows={false}
      swipe
      slidesToShow={perPage}
      slidesToScroll={perPage}
      {...rest}
    >
      {children}
    </Slider>
  )
}
Carousel.displayName = 'Carousel'

export default Carousel
