import React from 'react'
import { Caption, Quote } from './shared/Text'

const BlockQuote = ({ data }) => (
  <div className="px-6  lg:px-16">
    <blockquote className="text-white-200 container border-l-2 border-primary py-2 pl-6">
      <Quote>{data.quoteBody}</Quote>
      <cite className="mt-4 block uppercase not-italic">
        <Caption>{data.title}</Caption>
      </cite>
    </blockquote>
  </div>
)

export default BlockQuote
