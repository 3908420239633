import React from 'react'

const HighlightSection = ({ className = '', staticImage, header }) => (
  <div
    className={`relative w-screen overflow-hidden pb-0 lg:h-screen lg:max-h-[55vw] ${className}`}
  >
    <div className="relative text-right">
      {staticImage}
      <div className="absolute bottom-0 h-32 w-full bg-gradient-to-t from-background to-transparent" />
      <div className="absolute top-0 h-32 w-full bg-gradient-to-b from-background to-transparent" />
      <div className="responsive-overlay top-auto bottom-0 text-left lg:bottom-24">
        {header}
      </div>
    </div>
  </div>
)

export default HighlightSection
