import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Caption } from './shared/Text'

const BlockMediaWithCaption = ({ data }) => {
  const isVideo = data.file.mime.startsWith('video')
  const { caption } = data
  return (
    <div className="lg:px-14">
      {isVideo ? (
        <p>TODO video</p>
      ) : (
        <GatsbyImage
          image={getImage(data.file.localFile)}
          alt={data.file.alternativeText}
          className="rounded-lg"
        />
      )}
      {caption && (
        <p className="mx-auto text-center">
          <Caption className="italic">{caption}</Caption>
        </p>
      )}
    </div>
  )
}

export default BlockMediaWithCaption
