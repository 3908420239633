import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Carousel from './shared/Carousel/Carousel'

const BlockSlider = ({ data }) => (
  <div className="mx-auto w-full py-8">
    <Carousel className="">
      {data.files.map(file => (
        <div className="mx-auto my-0 flex flex-col justify-center lg:h-72 lg:w-72">
          <GatsbyImage
            className="lg:scale-90"
            key={file.id}
            image={getImage(file.localFile)}
            alt={file.alternativeText}
          />
        </div>
      ))}
    </Carousel>
  </div>
)

export default BlockSlider
