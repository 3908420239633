import React, { useCallback } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { firstNodeForLocale, getImage } from '../../utils/gatsby-strapi-helpers'
import HighlightSection from '../homeSections/HighlightSection'
import { LatestArticles } from '../homeSections/NewsTeaser'
import StayUpToDate from '../homeSections/StayUpToDate'
import Layout from '../layout'
import { HeroHeader } from '../sectionHeader'
import Seo from '../seo'
import { MainButton } from '../shared/Buttons'
import { SpecialHeader2 } from '../shared/Text'

const getSeo = article => ({
  metaTitle: article.title,
  metaDescription: article.description,
  shareImage: article.cover,
})

function ArticleLayout({ article, moreArticles, pageContext, children }) {
  const { locale } = pageContext
  const data = useStaticQuery(graphql`
    query generalArticleQuery {
      allStrapiNewsSection {
        nodes {
          altTitle
          buttonText
          menuItem {
            slug
          }
          locale
        }
      }

      allStrapiFeatured {
        nodes {
          locale
          publishedAt
          title
          description {
            data {
              description
            }
          }
          buttonText
          buttonTarget
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          blocks {
            ...Blocks
          }
        }
      }
    }
  `)

  const {
    allStrapiNewsSection: { nodes: newsSection },
    allStrapiFeatured: { nodes: featured },
  } = data
  const {
    menuItem: { slug: navbarSlug },
    buttonText: viewAllButtonText,
    altTitle: viewAllAltTitle,
  } = firstNodeForLocale(locale)(newsSection)

  const {
    title: featuredTitle,
    buttonText: featuredButtonText,
    buttonTarget: featuredButtonTarget,
    cover: featuredCover,
  } = firstNodeForLocale(locale)(featured)

  const seo = getSeo(article)

  const navigateTo = useCallback(
    () => navigate(featuredButtonTarget),
    [featuredButtonTarget]
  )

  return (
    <Layout as="article" pageContext={pageContext} navbarActive={navbarSlug}>
      <Seo seo={seo} />
      <HighlightSection
        className="lg:!h-3/4"
        header={
          <HeroHeader title={featuredTitle}>
            <MainButton onClick={navigateTo}>{featuredButtonText}</MainButton>
          </HeroHeader>
        }
        staticImage={
          <GatsbyImage
            image={getImage(featuredCover)}
            alt={featuredTitle}
            className="max-h-[70vh]"
          />
        }
      />
      <div className="flex flex-col gap-16 px-7">
        {/* vvvvv LAYOUT CHILDREN GO HERE */}
        {children}

        <div className="border-t-primaryFill h-fit min-h-screen border-t-2 border-solid border-primary lg:mx-40">
          <SpecialHeader2 className="py-5 lg:pt-20 lg:pb-12 lg:text-left">
            {viewAllAltTitle}
          </SpecialHeader2>
          <LatestArticles
            articles={moreArticles}
            buttonText={viewAllButtonText}
            showButton={false}
          />
        </div>
        <StayUpToDate className="my-auto flex h-screen flex-col justify-center" />
      </div>
    </Layout>
  )
}

export default ArticleLayout
