import React from 'react'
import { graphql } from 'gatsby'
import BlockRichText from './block-rich-text'
import BlockMedia from './block-media'
import BlockMediaWithCaption from './block-media-with-caption'
import BlockQuote from './block-quote'
import BlockSlider from './block-slider'

const componentsMap = {
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_SHARED_MEDIA: BlockMedia,
  STRAPI__COMPONENT_SHARED_MEDIA_WITH_CAPTION: BlockMediaWithCaption,
  STRAPI__COMPONENT_SHARED_QUOTE: BlockQuote,
  STRAPI__COMPONENT_SHARED_SLIDER: BlockSlider,
}

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  return <Component data={block} />
}

const BlocksRenderer = ({ blocks }) => (
  <div className="flex flex-col gap-8">
    {blocks.map((block, index) => (
      // TODO check what else to use as keys
      /* eslint react/no-array-index-key: 0 */
      <Block key={`${index}${block.__typename}`} block={block} />
    ))}
  </div>
)

export const query = graphql`
  fragment Blocks on STRAPI__COMPONENT_SHARED_MEDIASTRAPI__COMPONENT_SHARED_MEDIA_WITH_CAPTIONSTRAPI__COMPONENT_SHARED_QUOTESTRAPI__COMPONENT_SHARED_RICH_TEXTSTRAPI__COMPONENT_SHARED_SLIDERUnion {
    __typename
    ... on STRAPI__COMPONENT_SHARED_RICH_TEXT {
      richTextBody: body {
        __typename
        data {
          id
          body
          childMarkdownRemark {
            html
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_MEDIA {
      file {
        mime
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_SHARED_MEDIA_WITH_CAPTION {
      file {
        mime
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      caption
    }
    ... on STRAPI__COMPONENT_SHARED_QUOTE {
      title
      quoteBody: body
    }
    ... on STRAPI__COMPONENT_SHARED_SLIDER {
      files {
        id
        mime
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default BlocksRenderer
