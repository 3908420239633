import React from 'react'
import Markdown from './shared/Markdown'
import { Body } from './shared/Text'

const BlockRichText = ({ data }) => (
  <Body>
    <Markdown>{data.richTextBody.data.body}</Markdown>
  </Body>
)

export default BlockRichText
