import React from 'react'
import { graphql } from 'gatsby'

import BlocksRenderer from '../components/blocks-renderer'
import { Header } from '../components/shared/Text'
import ArticleLayout from '../components/news/ArticleLayout'

const ArticlePage = ({ data, pageContext }) => {
  const {
    strapiFeatured: article,
    moreArticles: { nodes: moreArticles },
  } = data

  return (
    <ArticleLayout
      article={article}
      moreArticles={moreArticles}
      pageContext={pageContext}
    >
      <header className="mt-8 flex w-full flex-col gap-8 pt-14 text-center lg:px-52">
        <Header className="">{article.title}</Header>
      </header>
      <main className="lg:px-52">
        <BlocksRenderer blocks={article.blocks || []} />
      </main>
    </ArticleLayout>
  )
}

export const pageQuery = graphql`
  query ($locale: String) {
    strapiFeatured(locale: { eq: $locale }) {
      publishedAt
      title
      description {
        data {
          description
        }
      }
      buttonText
      buttonTarget
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      blocks {
        ...Blocks
      }
    }

    moreArticles: allStrapiArticle(
      filter: { locale: { eq: $locale }, publishedAt: { ne: null } }
      sort: { fields: articleDate, order: DESC }
    ) {
      nodes {
        ...ArticleCard
      }
    }
  }
`

export default ArticlePage
